import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBanner = makeShortcode("AuthorBanner");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBanner mdxType="AuthorBanner" />
    <p>&nbsp;</p>
    <p><strong parentName="p">{`Web developer internship (Ubisoft), Montreal (Jan 2020 - Aug 2020)`}</strong></p>
    <ul>
      <li parentName="ul">{`Translate User Interface mockups into fully developed pages.`}</li>
      <li parentName="ul">{`Build, maintain and deploy an application using: React (UI framework), Redux (state management library), Material-UI.`}</li>
      <li parentName="ul">{`Maintain and deploy an application using: Webpack (module bundler), AngularJS (framework for the user interface), AWS (cloud storage for hosting), Sass (styling), CSS Grid (layout).`}</li>
      <li parentName="ul">{`Integrate multiple REST APIs and visualization tools from different backend teams into web applications.`}</li>
    </ul>
    <p><strong parentName="p">{`Web developer (Coopérative de solidarité territoires), Montreal (Jun 2019 - Sept 2019)`}</strong></p>
    <ul>
      <li parentName="ul">{`Translate user requirements to fully developed features, providing 1 new feature per week.`}</li>
      <li parentName="ul">{`Maintain an application that uses: Webpack (module bundler), React (framework for the user interface), Redux (state management library), Sass (styling), Flexbox (layout), and Mina (deployment).`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      